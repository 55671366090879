import { Button, Card, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Typography, FormLabel } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from '@material-ui/core/FormControl';
import CustomUnicefTable from '../../components/controls/CustomUnicefTable'
import CustomUnicefTextField from '../../components/controls/CustomUnicefTextField'
import CustomizedSnackbars from '../../components/controls/CustomSnackBar';
import Modals from "../../components/modal";
import _ from 'lodash';
import moment from "moment";

/////////////////////API////////////////////
import { getUnicefClinic } from '../../modals/unicefClinic'
import * as serviceData from '../../modals/htsservicedata'
import { insertHTS,updateHTS } from '../../modals/htsinfo';

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      flexGrow: 1
    }
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "#fcf0f2"
  },
  fontSize: {
    "& span:last-child": {
      fontSize: 13
    }
  },
  cardStyle: {
    marginBottom: theme.spacing(1),
  },
  cardStyleTwo: {
    width: "100%",
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.9),
    borderRadius: "3px"
  },
  formControl: {
    margin: theme.spacing(1),
  },

  table: {
    width: '100%',
    background: '#fcf0f2',
    height: '10px',


  },
  cellOne: {
    borderBottom: 'none',
    color: '#808080',
    fontSize: '9pt',

  },
  cellTwo: {
    borderBottom: 'none',
    color: '#53344d',
    fontSize: '12pt',
    fontWeight: 'bold',


  },
  secondFormControl: {
    margin: theme.spacing(1),
    width: '40%',
  },
  selected: {
    backgroundColor: "#DED4DA !important",
    color: '#482642'
  },
}));

const radioTheme = createTheme({
  palette: {
    primary: {
      main: "#482642"
    },
    secondary: {
      main: "#ffff"
    }
  }
});

export default function OTPForm() {

  let wfh = "WFH < -3 Z/ MUAC < 115 mm"

  const classes = useStyles();

  const history = useHistory();

  ///////Background Data///////////
  const [loading, setLoading] = useState(false);
  const [clinicData, setClinicData] = useState([]);
  const [clinicCode, setClinicCode] = useState('')
  const [divCode, setDivCode] = useState('')
  const [tspCode, setTspCode] = useState('')
  const [divName, setDivName] = useState('')
  const [tspName, setTspName] = useState('')

  const [newCase, setNewCase] = useState('')
  const [imamGVal, setImamGVal] = useState('')
  const [imamHVal, setImamHVal] = useState('')
  const [imamJVal, setImamJVal] = useState('')
  const [imamKVal, setImamKVal] = useState('')

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [successSnack, setSuccessSnack] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)

  const [formData, setFormData] = useState(
    {
      HTSDATE: '',
      HTSREGNAME: '',
      HTSREGID: '',
      HTSREGAGEUNIT: '',
      HTSREGAGE: '',
      HTSREGSEX: '',
      HTSPGW: '999',
      HTSSPSTATUS: '999',
      HSTEXPCHLD: '',
      HTSSPLGP: '999',
      HTSHIVTEST: '1',
      HTSHIVTESTRESULT: '999',
      HTSCONSELPRE: '999',
      HTSCONSELPOST: '999',
      HTSSYPHILL: '1',
      HTSRESULT: '999',
      HTSREMARK: '999',
      HTSUPDATE: '',
      HTSCLN: '',
      HTSTSP: '',
      HTSORG: '',
      HTSPROJ: '',
      HTSSTATUS: '1',
      HTSSYNC: '0',
    }
  )

  ////////////Handle Change//////////////////////////
  function sexHandleChange(event) {
    if (event.target.value === formData.HTSREGSEX) {
      setFormData({ ...formData, HTSREGSEX: "" })
    } else {
      setFormData({ ...formData, HTSREGSEX: event.target.value })
    }
  }


  const clinicHandleChange = (event) => {
    setClinicCode(event.target.value)
    let cData = _.find(clinicData, ['CLN_ID', event.target.value]);
    formData.HTSCLN = event.target.value
    formData.HTSTSP = cData.TSP_ID
    setTspCode(cData.TSP_ID)
    setTspName(cData.TSP_NAME)
    console.log("Selected Clinic => ", event.target.value)
  };

  const setSuccessSnackBarOpen = () => {
    setSuccessSnack(true)
  }

  const setSuccessSnackBarClose = () => {
    setSuccessSnack(false)
  }

  const setSnackBarOpen = () => {
    setOpenSnack(true)
  }

  const setSnackBarClose = () => {
    setOpenSnack(false)
  }




  const save = async () => {

    let valid = !formData.HTSDATE ? "ရက်စွဲကိုဖြည့်ပေးပါ။" :
      !formData.HTSREGNAME ? "အမည်ကိုဖြည့်ပေးပါ။" :
        !formData.HTSREGID ? "IDကိုဖြည့်ပေးပါ။" :
          !formData.HTSREGAGE ? "အသက်ကိုဖြည့်ပေးပါ။" :
            !formData.HTSREGAGEUNIT ? "Age Unitကိုရွေးပေးပါ။" :
              !formData.HTSCLN ? "ဆေးခန်းအမည်ကိုရွေးပေးပါ။"
                : 'valid';



    if (valid === 'valid') {

      formData.HTSUPDATE = moment(new Date()).format('YYYY-MM-DD hh:mm:ss')

      const rhres = await updateHTS({ formData });
      if (rhres?.status === 200) {
        //console.log("Save MDSR success!")
        setSuccess("Successfully updated!")
        setSuccessSnack(true)
        clear()
      }

    }

    else {

      setError(valid)

      setOpenSnack(true)

      console.log('Valid - ', valid)

    }

    console.log('formData ===>', formData)
  }

  const clear = () => {
    setFormData(
      {
        HTSDATE: '',
        HTSREGNAME: '',
        HTSREGID: '',
        HTSREGAGEUNIT: '',
        HTSREGAGE: '',
        HTSREGSEX: '',
        HTSPGW: '999',
        HTSSPSTATUS: '999',
        HSTEXPCHLD: '',
        HTSSPLGP: '999',
        HTSHIVTEST: '1',
        HTSHIVTESTRESULT: '999',
        HTSCONSELPRE: '999',
        HTSCONSELPOST: '999',
        HTSSYPHILL: '1',
        HTSRESULT: '999',
        HTSREMARK: '999',
        HTSUPDATE: '',
        HTSCLN: '',
        HTSTSP: '',
        HTSORG: '',
        HTSPROJ: '',
        HTSUPDATE: '',
        HTSSTATUS: '1',
        HTSSYNC: '0',
      }
    )
  }

  const cancle = () => {
    history.push('entryhomepage')
    sessionStorage.setItem('homeSave', 'done')
  }

  useEffect(async () => {

    setLoading(true)
    let clinic = await getUnicefClinic()
    let service = await serviceData.getServiceData()

    if (clinic.data.data.getUnicefClinic.length && service.data.data.getServiceData.length) {
      console.log("Unicef Clinic Data ========> ", clinic)

      let serviceData = await service.data.data.getServiceData;

      setClinicData(clinic.data.data.getUnicefClinic)
      let cData = _.find(clinic.data.data.getUnicefClinic, ['CLN_ID', serviceData[0].HTSCLN]);
      setTspCode(cData.TSP_ID)
      setTspName(cData.TSP_NAME)
      //setDivCode(clinic.data.data.getUnicefClinic[0].DIV_ID)
      //setDivName(clinic.data.data.getUnicefClinic[0].DIV_NAME)
      formData.ID = serviceData[0].ID
      formData.HTSDATE= moment(serviceData[0].HTSDATE).format("YYYY-MM-DD")
      formData.HTSREGNAME= serviceData[0].HTSREGNAME
      formData.HTSREGID= serviceData[0].HTSREGID
      formData.HTSREGAGEUNIT= serviceData[0].HTSREGAGEUNIT
      formData.HTSREGAGE= serviceData[0].HTSREGAGE
      formData.HTSREGSEX= serviceData[0].HTSREGSEX+''
      formData.HTSPGW= serviceData[0].HTSPGW
      formData.HTSSPSTATUS= serviceData[0].HTSSPSTATUS
      formData.HSTEXPCHLD= serviceData[0].HSTEXPCHLD
      formData.HTSSPLGP= serviceData[0].HTSSPLGP
      formData.HTSHIVTEST= serviceData[0].HTSHIVTEST
      formData.HTSHIVTESTRESULT= serviceData[0].HTSHIVTESTRESULT
      formData.HTSCONSELPRE= serviceData[0].HTSCONSELPRE
      formData.HTSCONSELPOST= serviceData[0].HTSCONSELPOST
      formData.HTSSYPHILL= serviceData[0].HTSSYPHILL
      formData.HTSRESULT= serviceData[0].HTSRESULT
      formData.HTSREMARK= serviceData[0].HTSREMARK
      formData.HTSUPDATE= serviceData[0].HTSUPDATE
      formData.HTSCLN= serviceData[0].HTSCLN
      formData.HTSTSP= serviceData[0].HTSTSP
      formData.HTSORG= serviceData[0].HTSORG
      formData.HTSPROJ= serviceData[0].HTSPROJ
      formData.HTSSTATUS= '2'
      formData.HTSSYNC= '0'
      
    }
    setLoading(false)

  }, [])

  return (
    <div style={{ width: '100%', height: '90vh', background: '#fcf0f2' }}>
      <Modals open={loading} />
      {successSnack && <CustomizedSnackbars open={setSuccessSnackBarOpen} close={setSuccessSnackBarClose} alertMsg={success} type="success" />}
      {openSnack && <CustomizedSnackbars open={setSnackBarOpen} close={setSnackBarClose} alertMsg={error} type="warning" />}
      <Typography variant="h6" align="center" style={{ color: '#53344d', background: '#fcf0f2', fontWeight: 'bold', padding: '1%' }}>
      HIV Testing Service</Typography>
      <CustomUnicefTable />

      <Card
        variant="outlined"
        style={{
          background: "#ddd3d5",
          marginTop: '10px',
          marginLeft: '5%',
          width: '90%',
          justifyContent: 'center',
        }}>
        <Grid container style={{ marginTop: '20px' }} >

          <Grid item spacing={2} xs={12} sm={12} md={2} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px', alignSelf: 'center' }}>
            <CustomUnicefTextField
              variantText="filled"
              type="date"
              label={<Grid row container><Typography color="#482642">Date </Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
              InputLabelProps={{ shrink: true }} style={{ width: '90%', backgroundColor: '#fcf0f2' }}
              onChange={e => {
                setFormData({ ...formData, HTSDATE: e.target.value })
              }}
              value={formData.HTSDATE} size="large" />
          </Grid>


          <Grid item spacing={2} xs={12} sm={12} md={2} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px', marginTop: '8px' }}>
            <CustomUnicefTextField
              label={<Grid row container><Typography color="#482642">Name </Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
              type="text"
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center' },
              }}
              style={{ width: '90%' }}
              onChange={e => {
                setFormData({ ...formData, HTSREGNAME: e.target.value })
              }}
              value={formData.HTSREGNAME} />
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px', marginTop: '8px' }}>
            <CustomUnicefTextField
              label={<Grid row container><Typography color="#482642">ID </Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
              type="text"
              disabled={serviceData}
              variantText="filled"
              multiline
              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center' },
              }}
              style={{ width: '100%', marginBottom: '10px' }}
              onChange={e => {
                setFormData({ ...formData, HTSREGID: e.target.value })
              }}
              value={formData.HTSREGID} />
          </Grid>

          <Grid item xs={12} sm={6} md={2} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px', marginTop: '8px' }}>

            <CustomUnicefTextField
              label={<Grid row container><Typography color="#482642">Clinic Name </Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
              select
              value={clinicCode}
              onChange={clinicHandleChange}
              variantText="filled"
              style={{ width: '80%' }}
              InputLabelProps={{
                style: { color: '#482642' },
                shrink: true
              }}
              SelectProps={{
                native: true
              }}>
              {clinicData.length &&
                clinicData.map((option) => (
                  <option key={option.CLN_ID} value={option.CLN_ID}>
                    {option.CLN_NAME}
                  </option>
                ))}
            </CustomUnicefTextField>
          </Grid>

          <Grid item xs={12} sm={6} md={2} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px', marginTop: '8px' }}>

            <CustomUnicefTextField
              variantText="filled"
              label='Township Name'
              disabled
              value={tspName}
              style={{ width: '80%' }} />
          </Grid>



          <Grid item spacing={2} xs={12} sm={12} md={1} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px', marginTop: '8px' }}>
            <CustomUnicefTextField
              label={<Grid row container><Typography color="#482642">Age </Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
              type="number"
              inputProps={{ step: "1", min: 0, maxLength: 2 }}
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center' },
              }}
              style={{ width: '80%' }}
              onChange={e => {
                setFormData({ ...formData, HTSREGAGE: e.target.value })
              }}
              value={formData.HTSREGAGE} />
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={2} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <ThemeProvider theme={radioTheme}>
              <FormControl variant="filled" className={classes.secondFormControl} style={{ width: '90%', backgroundColor: 'white' }}>
                <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642"  >Age Unit</Typography>
                  <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography>
                </Grid>}</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={formData.HTSREGAGEUNIT}
                  onChange={e => { setFormData({ ...formData, HTSREGAGEUNIT: e.target.value }) }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    style: {
                      maxHeight: 300,
                    },
                    getContentAnchorEl: null,
                  }}>
                  <MenuItem classes={{ selected: classes.selected }} value={'1'}>Days</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'30'}>Months</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'365'}>Years</MenuItem>

                </Select>
              </FormControl>
            </ThemeProvider>
          </Grid>

          <Grid item xs={12} sm={12} md={3} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px', marginTop: '7px' }}>
            <ThemeProvider theme={radioTheme}>
              <Card
                variant="outlined"
                style={{
                  background: "#fcf0f2",
                  width: '90%',
                  marginLeft: '17px',

                }}
                className={classes.cardStyle}>
                {<Grid row container style={{ justifyContent: 'center', marginTop: '3px' }}><Typography color="#482642">Sex</Typography>
                </Grid>}

                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                  }}
                  onChange={e => { setFormData({ ...formData, HTSREGSEX: e.target.value }) }}
                  value={formData.HTSREGSEX}
                  row={true}
                >
                  <FormControlLabel
                    value="1"
                    labelPlacement="left"
                    label="Male"
                    style={{ height: "30px" }}
                    className={classes.fontSize}
                    control={<Radio size="small" color="primary"
                      onClick={sexHandleChange} onKeyDown={e => e.key === 'Enter' && sexHandleChange(e)} />}
                  />
                  <FormControlLabel
                    value="2"
                    labelPlacement="left"
                    style={{ height: "30px" }}
                    className={classes.fontSize}
                    control={<Radio size="small" color="primary"
                      onClick={sexHandleChange} onKeyDown={e => e.key === 'Enter' && sexHandleChange(e)} />}
                    label="Female"
                  />
                </RadioGroup>
              </Card>
            </ThemeProvider>
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={2} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '15px' }}>
            <ThemeProvider theme={radioTheme}>
              <FormControl variant="filled" className={classes.secondFormControl} style={{ width: '80%', backgroundColor: 'white' }}>
                <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642" >Pregnant woman</Typography>
                </Grid>}</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={formData.HTSPGW}
                  onChange={e => { setFormData({ ...formData, HTSPGW: e.target.value }) }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    style: {
                      maxHeight: 300,
                    },
                    getContentAnchorEl: null,
                  }}>
                  <MenuItem classes={{ selected: classes.selected }} value={'1'}>PP</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'2'}>MP</MenuItem>
                </Select>
              </FormControl>
            </ThemeProvider>
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={2} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '15px' }}>
            <ThemeProvider theme={radioTheme}>
              <FormControl variant="filled" className={classes.secondFormControl} style={{ width: '80%', backgroundColor: 'white' }}>
                <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642" >Spouse of</Typography>
                </Grid>}</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={formData.HTSSPSTATUS}
                  onChange={e => { setFormData({ ...formData, HTSSPSTATUS: e.target.value }) }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    style: {
                      maxHeight: 300,
                    },
                    getContentAnchorEl: null,
                  }}>
                  <MenuItem classes={{ selected: classes.selected }} value={'1'}>Positive women</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'2'}>Negative Women</MenuItem>
                </Select>
              </FormControl>
            </ThemeProvider>
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={2} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <ThemeProvider theme={radioTheme}>
              <FormControl variant="filled" className={classes.secondFormControl} style={{ width: '80%', backgroundColor: 'white' }}>
                <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642" >Exposed Child</Typography>
                </Grid>}</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={formData.HSTEXPCHLD}
                  onChange={e => { setFormData({ ...formData, HSTEXPCHLD: e.target.value }) }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    style: {
                      maxHeight: 300,
                    },
                    getContentAnchorEl: null,
                  }}>
                  <MenuItem classes={{ selected: classes.selected }} value={'1'}>Yes</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'2'}>No</MenuItem>
                </Select>
              </FormControl>
            </ThemeProvider>
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={2} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '15px', marginTop: '25px' }}>
            <ThemeProvider theme={radioTheme}>
              <FormControl variant="filled" className={classes.secondFormControl} style={{ width: '90%', backgroundColor: 'white' }}>
                <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642"  >Special Groups</Typography>
                </Grid>}</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={formData.HTSSPLGP}
                  onChange={e => { setFormData({ ...formData, HTSSPLGP: e.target.value }) }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    style: {
                      maxHeight: 300,
                    },
                    getContentAnchorEl: null,
                  }}>
                  <MenuItem classes={{ selected: classes.selected }} value={'1'}>1</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'2'}>2</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'3'}>3</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'4'}>4</MenuItem>
                </Select>
              </FormControl>
            </ThemeProvider>
          </Grid>


          <Grid item spacing={2} xs={12} sm={12} md={1} style={{ alignContent: 'center', alignItems: 'center', color: '#fcf0f2', marginTop: '30px' }} >
            <CustomUnicefTextField
              disabled
              label='HIV Test'
              value='1st'
              variantText="filled"

              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center', fontWeight: 'bold' },
              }} />
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={2} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '15px', marginTop: '25px' }} >
            <ThemeProvider theme={radioTheme}>
              <FormControl variant="filled" className={classes.secondFormControl} style={{ width: '80%', backgroundColor: 'white' }}>
                <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642" >HIV Test Result</Typography>
                </Grid>}</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={formData.HTSHIVTESTRESULT}
                  onChange={e => { setFormData({ ...formData, HTSHIVTESTRESULT: e.target.value }) }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    style: {
                      maxHeight: 300,
                    },
                    getContentAnchorEl: null,
                  }}>
                  <MenuItem classes={{ selected: classes.selected }} value={'1'}>R</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'2'}>NR</MenuItem>
                </Select>
              </FormControl>
            </ThemeProvider>
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={2} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
            <Typography align='center' variant="subtitle1" color="#482642" style={{ background: '#ddd3d5', fontWeight: 'bold' }}  >Counselling</Typography>

            <Grid container roll>
              <Grid item spacing={2} xs={12} sm={12} md={6} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '15px' }} >
                <ThemeProvider theme={radioTheme}>
                  <FormControl variant="filled" className={classes.secondFormControl} style={{ width: '80%', backgroundColor: 'white' }}>
                    <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642" >Pre</Typography>
                    </Grid>}</InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={formData.HTSCONSELPRE}
                      onChange={e => { setFormData({ ...formData, HTSCONSELPRE: e.target.value }) }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        style: {
                          maxHeight: 300,
                        },
                        getContentAnchorEl: null,
                      }}>
                      <MenuItem classes={{ selected: classes.selected }} value={'1'}>Yes</MenuItem>
                      <MenuItem classes={{ selected: classes.selected }} value={'2'}>No</MenuItem>
                    </Select>
                  </FormControl>
                </ThemeProvider>
              </Grid>
              <Grid item spacing={2} xs={12} sm={12} md={6} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '15px' }} >
                <ThemeProvider theme={radioTheme}>
                  <FormControl variant="filled" className={classes.secondFormControl} style={{ width: '80%', backgroundColor: 'white' }}>
                    <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642" >Post</Typography>
                    </Grid>}</InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={formData.HTSCONSELPOST}
                      onChange={e => { setFormData({ ...formData, HTSCONSELPOST: e.target.value }) }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        style: {
                          maxHeight: 300,
                        },
                        getContentAnchorEl: null,
                      }}>
                      <MenuItem classes={{ selected: classes.selected }} value={'1'}>Yes</MenuItem>
                      <MenuItem classes={{ selected: classes.selected }} value={'2'}>No</MenuItem>
                    </Select>
                  </FormControl>
                </ThemeProvider>
              </Grid>
            </Grid>

          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={1} style={{ alignContent: 'center', alignItems: 'center', color: '#fcf0f2', marginTop: '30px' }} >
            <CustomUnicefTextField
              disabled
              label='Syphilis'
              value='VDRL'
              variantText="filled"

              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center', fontWeight: 'bold' },
              }} />
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={2} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '15px', marginTop: '25px' }} >
            <ThemeProvider theme={radioTheme}>
              <FormControl variant="filled" className={classes.secondFormControl} style={{ width: '80%', backgroundColor: 'white' }}>
                <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642" >Result</Typography>
                </Grid>}</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={formData.HTSRESULT}
                  onChange={e => { setFormData({ ...formData, HTSRESULT: e.target.value }) }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    style: {
                      maxHeight: 300,
                    },
                    getContentAnchorEl: null,
                  }}>
                  <MenuItem classes={{ selected: classes.selected }} value={'1'}>Positive</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'2'}>Negative</MenuItem>
                </Select>
              </FormControl>
            </ThemeProvider>
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={2} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '15px', marginTop: '25px' }} >
            <ThemeProvider theme={radioTheme}>
              <FormControl variant="filled" className={classes.secondFormControl} style={{ width: '80%', backgroundColor: 'white' }}>
                <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642" >Remark</Typography>
                </Grid>}</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={formData.HTSREMARK}
                  onChange={e => { setFormData({ ...formData, HTSREMARK: e.target.value }) }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    style: {
                      maxHeight: 300,
                    },
                    getContentAnchorEl: null,
                  }}>
                  <MenuItem classes={{ selected: classes.selected }} value={'1'}>*Restesting</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'2'}>**Labor</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'3'}>***Post-partum</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'4'}>**** Testing only</MenuItem>
                </Select>
              </FormControl>
            </ThemeProvider>
          </Grid>



        </Grid>
      </Card>


      <Grid container spacing={4} alignItems="center" justifyContent="center" style={{ paddingTop: '25px', background: '#fcf0f2' }} row>
        <Grid item xs={'auto'} style={{ width: '15%' }}>
          <Button
            variant="contained"
            style={{ background: '#482642', color: '#fff', width: '100%' }}
            onClick={save} >Update</Button>
        </Grid>
        <Grid item xs={'auto'} style={{ width: '15%' }}>
          <Button
            variant="contained"
            style={{ background: '#482642', color: '#fff', width: '100%' }}
            onClick={cancle} >Cancel</Button>
        </Grid>

      </Grid>
      <Typography variant="body1" align="center" style={{ color: 'gray', padding: '0.5%', background: '#fcf0f2' }}>
     Copyright © HMIS Facility Base System 2018-{new Date().getFullYear()}. </Typography>
    </div>
  )
}