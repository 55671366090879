import axios from 'axios';
import {api} from '../utils/helper';

//****************** GET HE Names for Search Bar******************//
export const editHENameShow = async (a) => {
    try{
    const resrep = await axios.post(
      `${api}/edithenameshow`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );
          console.log(`editHENameShow controller res: `, resrep);
          return resrep;
    }catch (error) {
        console.log(`editHENameShow controller error: `, error?.response?.data);
        alert(error?.response?.data?.message);
    }

}

//****************** GET serach HE******************//
export const editHEShow = async (a) => {
    try{
    const resrep = await axios.post(
      `${api}/editheshow`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );
          console.log(`editHEShow controller res: `, resrep);
          return resrep;
    }catch (error) {
        console.log(`editHEShow controller error: `, error?.response?.data);
        alert(error?.response?.data?.message);
    }

}

//****************** GET HEHD******************//
export const editHEHD = async (a) => {
  try{
  const resrep = await axios.post(
    `${api}/edithehd`, a,
    {
      headers: {
        authorization: sessionStorage.getItem('token'),
      },
    },
  );
        console.log(`editHEHD controller res: `, resrep);
        return resrep;
  }catch (error) {
      console.log(`editHEHD controller error: `, error?.response?.data);
      alert(error?.response?.data?.message);
  }

}

//****************** GET HE Topic******************//
export const editHETopic = async (a) => {
    try{
    const resrep = await axios.post(
      `${api}/edithetopic`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );
          console.log(`editHETopic controller res: `, resrep);
          return resrep;
    }catch (error) {
        console.log(`editHETopic controller error: `, error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }