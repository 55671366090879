import axios from 'axios';
import { api } from '../utils/helper';


//****************** GET Home Data By org,proj ******************//
export const getHomeShow = async () => {
  
    //console.log("OrgID from database => ", sessionStorage.getItem('org'))
  
    try {
      let a = { projID: sessionStorage.getItem('project') , orgID: sessionStorage.getItem('org') }
      console.log("a from getHomeShow rhform => ", a.projID, a.orgID)
  
      const resrep = await axios.post(
        `${api}/gethomeshow`, a,
        {
          headers: {
            authorization: sessionStorage.getItem('token'),
          },
        },
      );
  
      console.log(`getHomeShow controller res: `, resrep);
      return resrep;
    } catch (error) {
      console.log(`getHomeShow controller error: `, error?.response?.data);
      alert(error?.response?.data?.message);
    }
  
  }