import React, { useState } from "react";
import "./SearchBar.css";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Grid, List, ListItem } from "@material-ui/core";
import CustomOTPPatientTable from "./CustomOTPPatientTable";
import CustomSFPPatientTable from "./CustomSFPPatientTable";
import CustomUnicefTextField from "./CustomUnicefTextField";
import * as edit from "../../modals/editImamShow"
import Modals from "../modal";

function OTPPatientSearchBar({ placeholder, searchData, tableData }) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [patientID, setPatientID] = useState();
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchButtonHandle = async () => {
    setLoading(true);
    if (patientID && startDate && endDate) {
      const a = {
        ID: patientID,
        orgID: sessionStorage.getItem("org"),
        tblName: sessionStorage.getItem('tblName'),
        sn: sessionStorage.getItem('serviceName'),
        gmgmtype: '',
        sDate:startDate,
        eDate:endDate
      };
      let patient = await edit.getImamPatientIDDate(a);
      if (patient) {
        setSelectedPatient(patient.data.data.getImamPatientIDDate);
      }
    }
    else if (!patientID && startDate && endDate) 
    {
        const a = {
            orgID: sessionStorage.getItem("org"),
            tblName: sessionStorage.getItem('tblName'),
            sn: sessionStorage.getItem('serviceName'),
            gmgmtype: '',
            sDate:startDate,
            eDate:endDate
          };
          let patient = await edit.getImamPatientDate(a);
          if (patient) {
            setSelectedPatient(patient.data.data.getImamPatientDate);
          }
    } 
    else {
      sessionStorage.setItem("searchPatientBtn", patientID);
      let patient = await edit.getImamPatient();
      if (patient) {
        setSelectedPatient(patient.data.data.getImamPatient);
      }
    }
    setLoading(false);
  };

  return (

    <div>
      <Modals open={loading} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ paddingLeft: "1%", paddingRight: "1%", marginBottom: "10px" }}
      >
        <Grid item xs={3} sm={2} md={2}>
          <CustomUnicefTextField
            id="filled-basic"
            type="date"
            size="small"
            style={{ width: "90%" }}
            label="Start Date"
            variantText="filled"
            InputLabelProps={{
              style: { color: "#482642" },
              shrink: true,
            }}
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate}
          />
        </Grid>
        -
        <Grid item xs={3} sm={2} md={2}>
          <CustomUnicefTextField
            id="filled-basic"
            type="date"
            size="small"
            style={{ width: "90%" }}
            label="End Date"
            variantText="filled"
            InputLabelProps={{
              style: { color: "#482642" },
              shrink: true,
            }}
            onChange={(e) => setEndDate(e.target.value)}
            value={endDate}
          />
        </Grid>
        <Grid item xs={3} sm={2} md={2}>
          <CustomUnicefTextField
            id="filled-basic"
            type="text"
            size="small"
            style={{ width: "90%" }}
            label="Enter Patient ID"
            variantText="filled"
            InputLabelProps={{
              style: { color: "#482642" },
            }}
            onChange={(e) => setPatientID(e.target.value)}
            value={patientID}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Button
            variant="contained"
            size="large"
            disabled={!((startDate && endDate) || patientID)}
            style={{
              background: !((startDate && endDate) || patientID) ? "lightgray" : "#482642",
              color: !((startDate && endDate) || patientID) ? "#482642" : "#fff",
              width: "90%",
            }}
            onClick={searchButtonHandle}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      {(sessionStorage.getItem('tblName')==='tbl_imamotpn')? <CustomOTPPatientTable searchData={selectedPatient} tdata={tableData} /> : <CustomSFPPatientTable searchData={selectedPatient} tdata={tableData} />}
       
    </div>
  );
}

export default OTPPatientSearchBar;