import axios from 'axios';
import { api } from '../utils/helper';


//****************** GET Div By org,proj ******************//
export const getEWARDivision = async () => {
  
    //console.log("OrgID from database => ", sessionStorage.getItem('org'))
  
    try {
      let a = { projID: sessionStorage.getItem('project') , orgID: sessionStorage.getItem('org') }
      console.log("a from getEWARDivision  => ", a.projID, a.orgID)
  
      const resrep = await axios.post(
        `${api}/getewardivision`, a,
        {
          headers: {
            authorization: sessionStorage.getItem('token'),
          },
        },
      );
  
      console.log(`getewardivision controller res: `, resrep);
      return resrep;
    } catch (error) {
      console.log(`getewardivision controller error: `, error?.response?.data);
      alert(error?.response?.data?.message);
    }
  
  }

//****************** GET tsp By org,proj ******************//
export const getEWARTsp = async (a) => {
  
  //console.log("OrgID from database => ", sessionStorage.getItem('org'))

  try {
    console.log("a from getEWARTsp  => ", a.projID, a.orgID)

    const resrep = await axios.post(
      `${api}/getewartsp`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );

    console.log(`getEWARTsp controller res: `, resrep);
    return resrep;
  } catch (error) {
    console.log(`getEWARTsp controller error: `, error?.response?.data);
    alert(error?.response?.data?.message);
  }

}

//****************** GET Clinic By org,proj ******************//
export const getEWARClinic = async (a) => {
  
  //console.log("OrgID from database => ", sessionStorage.getItem('org'))

  try {
    console.log("a from getEWARClinic  => ", a.projID, a.orgID)

    const resrep = await axios.post(
      `${api}/getewarclinic`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );

    console.log(`getEWARClinic controller res: `, resrep);
    return resrep;
  } catch (error) {
    console.log(`getEWARClinic controller error: `, error?.response?.data);
    alert(error?.response?.data?.message);
  }

}

//****************** GET Village By org,proj ******************//
export const getEWARVillage = async (a) => {
  
  //console.log("OrgID from database => ", sessionStorage.getItem('org'))

  try {
    console.log("a from getEWARVillage  => ", a.projID, a.orgID)

    const resrep = await axios.post(
      `${api}/getewarvillage`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );

    console.log(`getEWARVillage controller res: `, resrep);
    return resrep;
  } catch (error) {
    console.log(`getEWARVillage controller error: `, error?.response?.data);
    alert(error?.response?.data?.message);
  }

}

//****************** GET Disease ******************//
export const getEWARDisease = async () => {
  
  //console.log("OrgID from database => ", sessionStorage.getItem('org'))

  try {
    let a = { projID: sessionStorage.getItem('project') , orgID: sessionStorage.getItem('org') }
    console.log("a from getEWARDisease  => ", a.projID, a.orgID)

    const resrep = await axios.post(
      `${api}/getewardisease`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );

    console.log(`getEWARDisease controller res: `, resrep);
    return resrep;
  } catch (error) {
    console.log(`getEWARDisease controller error: `, error?.response?.data);
    alert(error?.response?.data?.message);
  }

}