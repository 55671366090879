import axios from 'axios';
import {api} from '../utils/helper';

//****************** insert HTS ******************//
export const insertHTS = async (a) => {
    try{
        const resrep = await axios.post(`${api}/insertHTS`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('insertHTS controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('insertHTS controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }


//****************** update HTS ******************//
export const updateHTS = async (a) => {
    try{
        const resrep = await axios.put(`${api}/updateHTS`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('updateHTS controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('updateHTS controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }

//****************** delete HTS ******************//
export const deleteHTS = async (a) => {
    try{
        const resrep = await axios.put(`${api}/deleteHTS`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('deleteHTS controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('deleteHTS controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }