import axios from 'axios';
import {api} from '../utils/helper';

//****************** insert HE ******************//
export const insertHE = async (a) => {
    try{
        const resrep = await axios.post(`${api}/insertHE`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('insertHE controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('insertHE controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }

  //****************** update HE ******************//
export const updateHE = async (a) => {
  try{
      const resrep = await axios.post(`${api}/updateHE`,a, {
          headers: {
            authorization: sessionStorage.getItem('token'),
          },
        },);
        console.log('updateHE controller res: ', resrep);
        return resrep;
  }catch (error) {
      console.log('updateHE controller error: ', error?.response?.data);
      alert(error?.response?.data?.message);
  }

}

//****************** delete HE ******************//
export const deleteHE = async (a) => {
  try{
      const resrep = await axios.post(`${api}/deleteHE`,a, {
          headers: {
            authorization: sessionStorage.getItem('token'),
          },
        },);
        console.log('deleteHE controller res: ', resrep);
        return resrep;
  }catch (error) {
      console.log('deleteHE controller error: ', error?.response?.data);
      alert(error?.response?.data?.message);
  }

}

//****************** remove HE ******************//
export const removeHE = async (a) => {
  try{
      const resrep = await axios.post(`${api}/removeHE`,a, {
          headers: {
            authorization: sessionStorage.getItem('token'),
          },
        },);
        console.log('removeHE controller res: ', resrep);
        return resrep;
  }catch (error) {
      console.log('removeHE controller error: ', error?.response?.data);
      alert(error?.response?.data?.message);
  }

}

//****************** insert HETopic ******************//
export const insertHETopic = async (a) => {
  try{
      const resrep = await axios.post(`${api}/insertHETopic`,a, {
          headers: {
            authorization: sessionStorage.getItem('token'),
          },
        },);
        console.log('insertHETopic controller res: ', resrep);
        return resrep;
  }catch (error) {
      console.log('insertHETopic controller error: ', error?.response?.data);
      alert(error?.response?.data?.message);
  }

}

//****************** update HETopic ******************//
export const updateHETopic = async (a) => {
try{
    const resrep = await axios.post(`${api}/updateHETopic`,a, {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },);
      console.log('updateHETopic controller res: ', resrep);
      return resrep;
}catch (error) {
    console.log('updateHETopic controller error: ', error?.response?.data);
    alert(error?.response?.data?.message);
}

}

//****************** delete HETopic ******************//
export const deleteHETopic = async (a) => {
try{
    const resrep = await axios.post(`${api}/deleteHETopic`,a, {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },);
      console.log('deleteHETopic controller res: ', resrep);
      return resrep;
}catch (error) {
    console.log('deleteHETopic controller error: ', error?.response?.data);
    alert(error?.response?.data?.message);
}

}