import axios from 'axios';
import {api} from '../utils/helper';
//****************** GET MDSR by Org and Project ******************//
export const exportMDSR = async (a) => {
    try{
     // const a = { orgID: sessionStorage.getItem('org'), projID: sessionStorage.getItem('project')}
    const resrep = await axios.post(
      `${api}/exportMDSR`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );
          console.log(`exportMDSR controller res: `, resrep);
          return resrep;
    }catch (error) {
        console.log(`exportMDSR controller error: `, error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }