import React, { useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { Card } from "@mui/material";
import { Button, Typography } from "@material-ui/core";
import Modals from "../../components/modal";
import _ from 'lodash';

/////////Form////////////
import HEEditForm from '../../forms/GerenalService/HEEditForm'

////////Controls////////
import CustomHESearchBar from '../../components/controls/CustomHESearchBar'

const EditHEForm = props => {
    
    ///////Background Data///////////
    const [loading, setLoading] = useState(false);
    const [editPage, setEditPage] = useState(false)

      useEffect(() => {
        const fn = async () => {
          const edit = sessionStorage.getItem('editHE')
          setEditPage(edit)
          console.log("EWAREditShow from EWAR edit form", edit)
        }
        fn();
      }, [sessionStorage.getItem('editHE')])


    return(
        <div style={{ background: '#fcf0f2' }}>
      <Modals open={loading} />
      <Card style={{ background: '#fcf0f2' }}>
        {(editPage === "false") ?
          <><Typography variant="h5" align="center" style={{ color: '#53344d', background: '#fcf0f2', fontWeight: 'bold', padding: '1%' }}>
            Health Education Infomation</Typography>
            <CustomHESearchBar/></>

          : <HEEditForm editPage={editPage}></HEEditForm>}


      </Card >

    </div >
    )
}

export default EditHEForm;