import React, { useState, useEffect } from "react";
import "./SearchBar.css";
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Grid, List, ListItem, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import * as edit from "../../modals/service_editshow"
import Modals from "../modal";

import CustomHETable from './CustomHETable'
import CustomUnicefTextField from './CustomUnicefTextField'
import CustomHEDeleteDialog from '../controls/CustomHEDeleteDialog';

///////////////API/////////////////////
import { editHEShow } from '../../modals/editheshow'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));

function CustomHESearchBar() {

    const classes = useStyles();

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [selectedName, setSelectedName] = useState('')
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);

    const search = async () => {
        setLoading(true)
        let a = {}
        if (selectedName && startDate && endDate)
        {
            a = {
                orgID: sessionStorage.getItem('org'),
                HE_EDUNAME : selectedName,
                startDate : startDate,
                endDate : endDate,
                type : 'both'
            }
        }
        else if(!selectedName && startDate && endDate)
        {
            a = {
                orgID: sessionStorage.getItem('org'),
                startDate : startDate,
                endDate : endDate,
                type : 'date'
            }
        }
        else 
        {
            a = {
                orgID: sessionStorage.getItem('org'),
                HE_EDUNAME : selectedName,
                type : 'name'
            }
        }
        let res = await editHEShow(a)
        if (res) {
            console.log('Data => ', res.data.data.editHEShow)
            setTableData(res.data.data.editHEShow)
        }
        setLoading(false)
        //console.log('a => ', a)
    }

    const [dialogOpen, setDialogOpen] = useState(false)

    const history = useHistory();

    //Dialog
    const setDialogOpenControl = () => {
        setDialogOpen(true)
    }
    const setDialogCloseControl = async () => {
        setDialogOpen(false)
    }

    return (

        <div>
            <Modals open={loading} />
            {dialogOpen && <CustomHEDeleteDialog onClose={setDialogCloseControl} open={setDialogOpenControl} />}
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ paddingLeft: "1%", paddingRight: "1%", marginBottom: "10px" }}
            >
                <Grid item xs={3} sm={2} md={2}>
                    <CustomUnicefTextField
                        id="filled-basic"
                        type="date"
                        size="small"
                        style={{ width: "90%" }}
                        label="Start Date"
                        variantText="filled"
                        InputLabelProps={{
                            style: { color: "#482642" },
                            shrink: true,
                        }}
                        onChange={(e) => setStartDate(e.target.value)}
                        value={startDate}
                    />
                </Grid>
                -
                <Grid item xs={3} sm={2} md={2}>
                    <CustomUnicefTextField
                        id="filled-basic"
                        type="date"
                        size="small"
                        style={{ width: "90%" }}
                        label="End Date"
                        variantText="filled"
                        InputLabelProps={{
                            style: { color: "#482642" },
                            shrink: true,
                        }}
                        onChange={(e) => setEndDate(e.target.value)}
                        value={endDate}
                    />
                </Grid>
                <Grid item xs={3} sm={2} md={2}>
                    <CustomUnicefTextField
                        id="filled-basic"
                        type="text"
                        size="small"
                        style={{ width: "90%" }}
                        label="Health Educator Name"
                        variantText="filled"
                        InputLabelProps={{
                            style: { color: "#482642" },
                        }}
                        onChange={(e) => setSelectedName(e.target.value)}
                        value={selectedName}
                    />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                    <Button
                        variant="contained"
                        size="large"
                        disabled={!((startDate && endDate) || selectedName)}
                        style={{
                            background: !((startDate && endDate) || selectedName) ? "lightgray" : "#482642",
                            color: !((startDate && endDate) || selectedName) ? "#482642" : "#fff",
                            width: "90%",
                        }}
                        onClick={search}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>

            <CustomHETable searchData={tableData} />
        </div>
    );
}

export default CustomHESearchBar;