import axios from 'axios';
import { api } from '../utils/helper';

//****************** GET Division ******************//
export const getAllHEDivision = async (a) => {

    console.log("OrgID from database => ", sessionStorage.getItem('org'))

    try {

        console.log("a from getAllHEDivision rhform => ", a.projID, a.orgID)

        const resrep = await axios.post(
            `${api}/getallhedivision`, a,
            {
                headers: {
                    authorization: sessionStorage.getItem('token'),
                },
            },
        );

        console.log(`getAllHEDivision controller res: `, resrep);
        return resrep;
    } catch (error) {
        console.log(`getAllHEDivision controller error: `, error?.response?.data);
        alert(error?.response?.data?.message);
    }

}

//****************** GET Township by div ******************//
export const getHETsp = async (a) => {

    console.log("OrgID from database => ", sessionStorage.getItem('org'))

    try {

        console.log("a from getHETsp rhform => ", a.projID, a.orgID)

        const resrep = await axios.post(
            `${api}/gethetsp`, a,
            {
                headers: {
                    authorization: sessionStorage.getItem('token'),
                },
            },
        );

        console.log(`getHETsp controller res: `, resrep);
        return resrep;
    } catch (error) {
        console.log(`getHETsp controller error: `, error?.response?.data);
        alert(error?.response?.data?.message);
    }

}

//****************** GET Village by tsp ******************//
export const getHEVillage = async (a) => {

    console.log("OrgID from database => ", sessionStorage.getItem('org'))

    try {

        console.log("a from getHEVillage rhform => ", a.projID, a.orgID)

        const resrep = await axios.post(
            `${api}/gethevillage`, a,
            {
                headers: {
                    authorization: sessionStorage.getItem('token'),
                },
            },
        );

        console.log(`getHEVillage controller res: `, resrep);
        return resrep;
    } catch (error) {
        console.log(`getHEVillage controller error: `, error?.response?.data);
        alert(error?.response?.data?.message);
    }

}

//****************** GET HE Topic ******************//
export const getHETopic = async (a) => {

    console.log("OrgID from database => ", sessionStorage.getItem('org'))

    try {

        console.log("a from getHETopic rhform => ", a.projID, a.orgID)

        const resrep = await axios.post(
            `${api}/gethetopic`, a,
            {
                headers: {
                    authorization: sessionStorage.getItem('token'),
                },
            },
        );

        console.log(`getHETopic controller res: `, resrep);
        return resrep;
    } catch (error) {
        console.log(`getHETopic controller error: `, error?.response?.data);
        alert(error?.response?.data?.message);
    }

}

//****************** GET MaxID ******************//
export const getHEMaxID = async (a) => {

    console.log("OrgID from database => ", sessionStorage.getItem('org'))

    try {

        console.log("a from getHEMaxID rhform => ", a.projID, a.orgID)

        const resrep = await axios.post(
            `${api}/gethemaxid`, a,
            {
                headers: {
                    authorization: sessionStorage.getItem('token'),
                },
            },
        );

        console.log(`getHEMaxID controller res: `, resrep);
        return resrep;
    } catch (error) {
        console.log(`getHEMaxID controller error: `, error?.response?.data);
        alert(error?.response?.data?.message);
    }

}

//****************** GET MaxID ******************//
export const getHEMaxIDDTL = async (a) => {

    console.log("OrgID from database => ", sessionStorage.getItem('org'))

    try {

        console.log("a from getHEMaxIDDTL rhform => ", a.projID, a.orgID)

        const resrep = await axios.post(
            `${api}/gethemaxiddtl`, a,
            {
                headers: {
                    authorization: sessionStorage.getItem('token'),
                },
            },
        );

        console.log(`getHEMaxIDDTL controller res: `, resrep);
        return resrep;
    } catch (error) {
        console.log(`getHEMaxIDDTL controller error: `, error?.response?.data);
        alert(error?.response?.data?.message);
    }

}