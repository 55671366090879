import axios from 'axios';
import {api} from '../utils/helper';

//****************** insert EWAR ******************//
export const insertEWAR = async (a) => {
    try{
        const resrep = await axios.post(`${api}/insertEWAR`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('insertEWAR controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('insertEWAR controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }

  //****************** update EWAR ******************//
export const updateEWAR = async (a) => {
  try{
      const resrep = await axios.post(`${api}/updateEWAR`,a, {
          headers: {
            authorization: sessionStorage.getItem('token'),
          },
        },);
        console.log('updateEWAR controller res: ', resrep);
        return resrep;
  }catch (error) {
      console.log('updateEWAR controller error: ', error?.response?.data);
      alert(error?.response?.data?.message);
  }

}

//****************** delete EWAR ******************//
export const deleteEWAR = async (a) => {
  try{
      const resrep = await axios.post(`${api}/deleteEWAR`,a, {
          headers: {
            authorization: sessionStorage.getItem('token'),
          },
        },);
        console.log('deleteEWAR controller res: ', resrep);
        return resrep;
  }catch (error) {
      console.log('deleteEWAR controller error: ', error?.response?.data);
      alert(error?.response?.data?.message);
  }

}