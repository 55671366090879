import axios from 'axios';
import {api} from '../utils/helper';

//****************** GET All HTS Patients by Org ******************//
export const editHtsShow = async () => {
    try{
      const a = { orgID: sessionStorage.getItem('org'), 
      projID:sessionStorage.getItem('project')}
    const resrep = await axios.post(
      `${api}/edithtsshow`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );
          console.log(`editHtsShow controller res: `, resrep);
          return resrep;
    }catch (error) {
        console.log(`editHtsShow controller error: `, error?.response?.data);
        alert(error?.response?.data?.message);
    }

}