import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Button, Card, Paper, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Typography, FormLabel, FormControl } from '@material-ui/core';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useAutocomplete, AutocompleteGetTagProps } from '@mui/base/useAutocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import moment from "moment";
import _ from 'lodash';

import CustomUnicefTextField from "../../components/controls/CustomUnicefTextField";
import CustomSnackBar from "../../components/controls/CustomSnackBar";
import Modals from "../../components/modal";

//////////////API/////////////////
import { getAllHEDivision, getHETsp, getHEVillage, getHETopic, getHEMaxID, getHEMaxIDDTL } from "../../modals/hedata";
import { insertHE, deleteHE, insertHETopic } from "../../modals/heinfo";

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },

}));

const radioTheme = createTheme({
  palette: {
    primary: {
      main: "#917c8d"
    },
    secondary: {
      main: "#ffff"
    }
  }
});

const ageUnder18 = 'AGE <=18'
const ageBetween1924 = 'AGE 19-24'
const ageAbove25 = 'AGE >=25'

function HEForm() {

  const history = useHistory();

  /* classes style */
  const classes = useStyles();
  const [state, setState] = useState([]);
  const [tsp, setTsp] = useState([]);
  const [vil, setVil] = useState([]);

  const [topic, setTopic] = useState([])
  const [selectedTopic, setSelectedTopic] = useState([])

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [successSnack, setSuccessSnack] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)

  const [loading, setLoading] = useState(false);

  const setSnackBarOpen = () => {
    setOpenSnack(true)
  }

  const setSnackBarClose = () => {
    setOpenSnack(false)
  }

  const setSuccessSnackBarOpen = () => {
    setSuccessSnack(true)
  }

  const setSuccessSnackBarClose = () => {
    setSuccessSnack(false)
  }

  const [formData, setFormData] = useState({
    HE_HDID: "",
    HE_DIV: "",
    HE_TSP: "",
    HE_TSPOTHER: "",
    HE_VILLAGE: "",
    HE_VILAGEOTHER: "",
    HE_PROVIDEDDATE: "",
    HE_ORG: "",
    HE_PROJECT: "",
    HE_DONOR: "",
    HE_DTLAGEL18M: "",
    HE_DTLAGEL18F: "",
    HE_DTLAGE1924M: "",
    HE_DTLAGE1924F: "",
    HE_DTLAGEL25M: "",
    HE_DTLAGEL25F: "",
    HE_EDUNAME: "",
    HE_EDUPOSITION: "",
    HE_REMARK: "",
    HE_URLOGIN: "",
    HE_INSERT: "",
    HE_UPDATE: "",
    HE_STATUS: "",
    HE_SYNC: "",
    HE_TOPICOTHER: "",
  })

  const [DTLForm, setDTLForm] = useState([])
  const [topicInsert, setTopicInsert] = useState(false)

  const stateHandle = async (event) => {
    //setState(event.target.value);
    formData.HE_DIV = event.target.value
    const a =
    {
      projID: sessionStorage.getItem('project'),
      orgID: sessionStorage.getItem('org'),
      divID: event.target.value
    }
    let tspData = await getHETsp(a)
    if (tspData) {
      console.log('Tsp : ', tspData.data.data.getHETsp)
      setTsp(tspData.data.data.getHETsp)
    }
  };

  const tspHandle = async (event) => {
    //setTsp(event.target.value)
    formData.HE_TSP = event.target.value
    const a = { projID: sessionStorage.getItem('project'), orgID: sessionStorage.getItem('org'),
     divID: (event.target.value) ==='OTH-TSP-001' ? 'DIV-16' : formData.HE_DIV, tspID:  event.target.value}
    let villData = await getHEVillage(a)
    if (villData) {
      console.log('Tsp : ', villData.data.data.getHEVillage)
      setVil(villData.data.data.getHEVillage)
    }
  }

  const topicHandleChange = (event, obj) => {
    //console.log('event => ', event.target)
    //console.log("obj => ", obj)
    let arr = []
    for (let i = 0; i < obj.length; i++) {
      arr.push({
        HE_NAME: obj[i].HE_NAME,
        HE_TOPICID: obj[i].HE_TOPICID
      })
    }
    setSelectedTopic(arr)
    console.log("selectedtopic  => ", arr)
  }

  const cancle = () => {
    history.push('entryhomepage')
    sessionStorage.setItem('homeSave', 'done')
  }

  const clear = () => {
    formData.HE_HDID = ''
    formData.HE_TSP = ''
    formData.HE_TSPOTHER = ''
    formData.HE_VILLAGE = ''
    formData.HE_VILAGEOTHER = ''
    formData.HE_PROVIDEDDATE = ''
    formData.HE_DTLAGEL18M = ''
    formData.HE_DTLAGEL18F = ''
    formData.HE_DTLAGE1924M = ''
    formData.HE_DTLAGE1924F = ''
    formData.HE_DTLAGEL25M = ''
    formData.HE_DTLAGEL25F = ''
    formData.HE_EDUNAME = ''
    formData.HE_EDUPOSITION = ''
    formData.HE_REMARK = ''
    formData.HE_URLOGIN = ''
    formData.HE_TOPICOTHER = ''
    setSelectedTopic([])
  }

  const save = async () => {
    setLoading(true)
    const valid = (!formData.HE_PROVIDEDDATE) ? 'Please choose Date' :
      (!formData.HE_DIV) ? 'Please choose State/Region' :
        (!formData.HE_TSP) ? 'Please choose Township' :
          (!formData.HE_VILLAGE) ? 'Please choose Township' :
            (!formData.HE_EDUNAME) ? 'Please enter Health Educator Name' :
              (!formData.HE_EDUPOSITION) ? 'Please enter Health Educator Designation' :
                  'valid'
    if (valid === 'valid') {
      let a = { orgID: sessionStorage.getItem('org'), projID: sessionStorage.getItem('project') }

      let maxIDCount = await getHEMaxID(a)
      let maxIDCountDTL = await getHEMaxIDDTL(a)
      //console.log('maxID => ',maxIDCount.data.data.getHEMaxID)

      const maxID = maxIDCount.data.data.getHEMaxID
      const maxIDDTL = maxIDCountDTL.data.data.getHEMaxIDDTL

      formData.HE_HDID = maxID[0].MAXID + 1
      formData.HE_ORG = sessionStorage.getItem('org')
      formData.HE_PROJECT = sessionStorage.getItem('project')
      formData.HE_DONOR = sessionStorage.getItem('donor')
      formData.HE_URLOGIN = sessionStorage.getItem('userName')
      let date = moment(formData.HE_PROVIDEDDATE).format("YYYY-MM-DD hh:mm:ss")
      formData.HE_PROVIDEDDATE = date
      formData.HE_INSERT = moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
      formData.HE_UPDATE = moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
      formData.HE_STATUS = '1'
      formData.HE_SYNC = '0'

      let formArr = []
      for (let i = 0; i < selectedTopic.length; i++) {
        formArr.push({
          HE_DTLID: maxIDDTL[0].MAXID + (i + 1),
          HE_HDID: maxID[0].MAXID + 1,
          HE_TOPICID: selectedTopic[i].HE_TOPICID,
          HE_ORG: sessionStorage.getItem('org')
        })
      }
      setDTLForm(formArr)

      console.log('FormData => ', formData)
      console.log('DTLData => ', formArr)

      const res = await insertHE({ formData, formArr })

      if (res?.status === 200) {
        setSuccess('Successfully insert HE Data')
        setSuccessSnackBarOpen()
        clear()
      }
    }

    else {
      setError(valid)
      setSnackBarOpen()
    }
    setLoading(false)

  }

  useEffect(async () => {

    let a = { orgID: sessionStorage.getItem('org'), projID: sessionStorage.getItem('project') }
    let div = await getAllHEDivision(a);
    let topicData = await getHETopic(a);

    if (div && topicData) {
      //console.log('div => ', div.data.data.getAllHEDivision)
      //console.log('topic => ', topicData.data.data.getHETopic)

      setState(div.data.data.getAllHEDivision)
      const state = div.data.data.getAllHEDivision[0].DIV_ID
      formData.HE_DIV = state
      const a =
      {
        projID: sessionStorage.getItem('project'),
        orgID: sessionStorage.getItem('org'),
        divID: state
      }
      let tspData = await getHETsp(a)
      if (tspData) {
        console.log('Tsp : ', tspData.data.data.getHETsp)
        setTsp(tspData.data.data.getHETsp)
      }
      setTopic(topicData.data.data.getHETopic)

    }

  }, []);

  return (
    <div style={{ width: '100%', background: '#ffffff' }}>
      <Modals open={loading} />
      {openSnack && <CustomSnackBar open={setSnackBarOpen} close={setSnackBarClose} alertMsg={error} type="warning" />}
      {successSnack && <CustomSnackBar open={setSuccessSnackBarOpen} close={setSuccessSnackBarClose} alertMsg={success} type="success" />}
      <Typography
        variant="h5"
        align="center"
        style={{ color: "#53344d", fontWeight: "bold", padding: "1%" }}>
        Health Education
      </Typography>
      {/* <ThemeProvider theme={radioTheme}> */}

      <Paper elevation={3}
        style={{
          marginTop: '10px',
          marginLeft: '5%',
          width: '90%',
          justifyContent: 'center',
        }}>
        <Grid container style={{ marginTop: '20px', justifyContent: 'center' }} >

          {/* date */}
          <Grid item xs={12} sm={12} md={3} style={{ alignContent: 'center', alignItems: 'center', }}>
            <ThemeProvider theme={radioTheme}>
              <CustomUnicefTextField
                style={{ width: '90%' }}
                type='date'
                id="filled-basic"
                variantText="filled"
                className={classes.formControl}
                label={<Grid row container><Typography color="#482642">Date</Typography>
                  <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
                InputLabelProps={{
                  style: { color: "#482642" },
                  shrink: true,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData, HE_PROVIDEDDATE: e.target.value
                  });
                }}
                value={formData.HE_PROVIDEDDATE} />
            </ThemeProvider>
          </Grid>
          {/* state */}
          <Grid item xs={12} sm={12} md={3} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <FormControl variant="filled" className={classes.formControl} style={{ width: '90%' }}>
              <InputLabel id="demo-controlled-open-select-label">{<Grid row container><Typography color="#482642">State/Region</Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}</InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                value={formData.HE_DIV}
                disabled
                onChange={stateHandle}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  style: {
                    maxHeight: 300,
                  },
                  getContentAnchorEl: null,
                }}
              >
                {state.length &&
                  state.map((option) => (
                    <MenuItem key={option.DIV_ID} value={option.DIV_ID}>
                      {option.STATEREGION}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>


          </Grid>

          {/* tsp */}
          <Grid item xs={12} sm={12} md={3} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <FormControl variant="filled" className={classes.formControl} style={{ width: '90%' }}>
              <InputLabel id="demo-controlled-open-select-label">{<Grid row container><Typography color="#482642">Township</Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}</InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                value={formData.HE_TSP}
                onChange={tspHandle}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  style: {
                    maxHeight: 300,
                  },
                  getContentAnchorEl: null,
                }}
              >
                {tsp.length &&
                  tsp.map((option) => (
                    <MenuItem key={option.TSP_ID} value={option.TSP_ID}>
                      {option.TSPNAME}
                    </MenuItem>
                  ))}

              </Select>
            </FormControl>
          </Grid>
          {formData.HE_TSP === 'OTH-TSP-001' &&
            <Grid item xs={12} sm={12} md={3}>
              <CustomUnicefTextField
                multiline
                id="filled-basic"
                label="Other Township"
                variantText="filled"
                className={classes.formControl} style={{ width: '90%' }}
                onChange={(e) => {
                  setFormData({ ...formData, HE_TSPOTHER: e.target.value });
                }}
                value={formData.HE_TSPOTHER}
              />
            </Grid>

          }

          {/* vil */}
          <Grid item xs={12} sm={12} md={3} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <FormControl variant="filled" className={classes.formControl} style={{ width: '90%' }}>
              <InputLabel id="demo-controlled-open-select-label">{<Grid row container><Typography color="#482642">Village</Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}</InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                value={formData.HE_VILLAGE}
                onChange={(e) => {
                  setFormData({ ...formData, HE_VILLAGE: e.target.value });
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  style: {
                    maxHeight: 300,
                  },
                  getContentAnchorEl: null,
                }}>
                {vil.length &&
                  vil.map((option) => (
                    <MenuItem key={option.VILLAGE_CODE} value={option.VILLAGE_CODE}>
                      {option.VILLAGENAME}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          {formData.HE_VILLAGE === 'OTH-001-VIL-001' &&
            <Grid item xs={12} sm={12} md={3}>
              <CustomUnicefTextField
                multiline
                id="filled-basic"
                label="Other Village"
                variantText="filled"
                className={classes.formControl} style={{ width: '90%' }}
                onChange={(e) => {
                  setFormData({ ...formData, HE_VILAGEOTHER: e.target.value });
                }}
                value={formData.HE_VILAGEOTHER}
              />
            </Grid>
          }
        </Grid>
      </Paper>
      {/* 2nd paper */}
      <Paper
        elevation={3}
        style={{
          marginTop: '10px',
          marginLeft: '5%',
          width: '90%',
          justifyContent: 'center',
        }}>
        <Grid container style={{ marginTop: '20px', justifyContent: 'center' }} >

          {/* ui 5 */}
          <Grid item xs={12} sm={12} md={12} style={{alignContent: 'center', alignItems: 'center', marginTop: '20px' }}>
            <Autocomplete
              style={{ alignItems: 'center', width:'90%',margin:'auto' }}
              multiple
              id="tags-filled"
              options={topic}
              getOptionLabel={(option) => option.HE_NAME}
              onChange={(e, obj) => topicHandleChange(e, obj)}
              /*  defaultValue={[he[2].title]} */
              value={selectedTopic}
              disableCloseOnSelect
              isOptionEqualToValue={(option, value) =>
                option.HE_TOPICID === value.HE_TOPICID
              }
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option.HE_NAME} {...getTagProps({ index })} style={{ marginTop: '1%', marginBottom: '1%' }} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="HE Topic"
                />
              )}
            />
          </Grid>
          {selectedTopic.findIndex(x => x.HE_TOPICID ==="TOP-039") >=0 && 
          <Grid item xs={12} sm={4} md={4} style={{ alignContent: 'center', alignItems: 'center', marginTop: '20px' }}>
          <CustomUnicefTextField
                multiline
                id="filled-basic"
                label="HE Topic Other"
                variantText="filled"
                className={classes.formControl} style={{ width: '100%' }}
                onChange={(e) => {
                  setFormData({ ...formData, HE_TOPICOTHER: e.target.value });
                }}
                value={formData.HE_TOPICOTHER}
              />
          </Grid>}
          

          <Grid container style={{ marginTop: '20px', justifyContent: 'center' }} >
            {/* card for Age/ Sex Disaggregated  under 18*/}
            <Grid item xs={12} sm={12} md={3} style={{ marginBottom: '20px' }}>
              <Paper elevation={3} style={{ width: '90%', justifyContent: 'center', }}>
                <Grid container style={{ justifyContent: 'center' }} >
                  <Grid item xs={12} sm={12} md={6} style={{ margin: '10px' }}>
                    <Typography variant="h6" gutterBottom style={{ margin: '10px' }}>
                      {ageUnder18}
                    </Typography>
                  </Grid>
                  <ThemeProvider theme={radioTheme}>
                    <Grid item xs={12} sm={12} md={6} style={{ margin: '10px' }}>
                      <TextField id="outlined-basic" type='number' label="Male" variant="outlined"
                        onChange={(e) => {
                          setFormData({ ...formData, HE_DTLAGEL18M: e.target.value });
                        }}
                        value={formData.HE_DTLAGEL18M} />
                    </Grid></ThemeProvider>

                  <Grid item xs={12} sm={12} md={6} style={{ margin: '10px', marginBottom: '20px' }}>
                    <TextField id="outlined-basic" type='number' label="Female" variant="outlined"
                      onChange={(e) => {
                        setFormData({ ...formData, HE_DTLAGEL18F: e.target.value });
                      }}
                      value={formData.HE_DTLAGEL18F} />
                  </Grid>

                </Grid>

              </Paper>
            </Grid>


            {/* card for Age/ Sex Disaggregated  19 above*/}
            <Grid item xs={12} sm={12} md={3} style={{ marginBottom: '20px' }}>
              <Paper elevation={3} style={{ width: '90%', justifyContent: 'center', }}>
                <Grid container style={{ justifyContent: 'center' }} >
                  <Grid item xs={12} sm={12} md={6} style={{ margin: '10px' }}>
                    <Typography variant="h6" gutterBottom style={{ margin: '10px' }}>
                      {ageBetween1924}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} style={{ margin: '10px' }}>

                    <TextField id="outlined-basic" type='number' label="Male" variant="outlined"
                      onChange={(e) => {
                        setFormData({ ...formData, HE_DTLAGE1924M: e.target.value });
                      }}
                      value={formData.HE_DTLAGE1924M} />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} style={{ margin: '10px', marginBottom: '20px' }}>
                    <TextField id="outlined-basic" type='number' label="Female" variant="outlined"
                      onChange={(e) => {
                        setFormData({ ...formData, HE_DTLAGE1924F: e.target.value });
                      }}
                      value={formData.HE_DTLAGE1924F} />
                  </Grid>

                </Grid>

              </Paper>
            </Grid>


            {/* card for Age/ Sex Disaggregated 25 and above*/}
            <Grid item xs={12} sm={12} md={3} style={{ marginBottom: '20px' }}>
              <Paper elevation={3} style={{ width: '90%', justifyContent: 'center', }}>
                <Grid container style={{ justifyContent: 'center' }} >
                  <Grid item xs={12} sm={12} md={6} style={{ margin: '10px' }}>
                    <Typography variant="h6" gutterBottom style={{ margin: '10px' }}>
                      {ageAbove25}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} style={{ margin: '10px' }}>

                    <TextField id="outlined-basic" type='number' label="Male" variant="outlined"
                      onChange={(e) => {
                        setFormData({ ...formData, HE_DTLAGEL25M: e.target.value });
                      }}
                      value={formData.HE_DTLAGEL25M} />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} style={{ margin: '10px', marginBottom: '20px' }}>
                    <TextField id="outlined-basic" type='number' label="Female" variant="outlined"
                      onChange={(e) => {
                        setFormData({ ...formData, HE_DTLAGEL25F: e.target.value });
                      }}
                      value={formData.HE_DTLAGEL25F} />
                  </Grid>

                </Grid>

              </Paper>
            </Grid>

          </Grid>
        </Grid>
      </Paper>


      {/* 3rd paper */}
      <Paper elevation={3}
        style={{
          marginTop: '10px',
          marginLeft: '5%',
          width: '90%',
          justifyContent: 'center',
          marginBottom: '20px'
        }}>
        <Grid container style={{ marginTop: '20px', justifyContent: 'center' }} >
          <Grid item xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px', marginTop: '5px' }}>
            <CustomUnicefTextField
              id="filled-basic"
              label={<Grid row container><Typography color="#482642">Health Educator Name</Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
              variantText="filled"
              className={classes.formControl}
              style={{ width: '90%' }}
              onChange={(e) => {
                setFormData({ ...formData, HE_EDUNAME: e.target.value });
              }}
              value={formData.HE_EDUNAME}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px', marginTop: '5px' }}>
            <CustomUnicefTextField
              id="filled-basic"
              label={<Grid row container><Typography color="#482642">Health Educator Designation</Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
              variantText="filled"
              className={classes.formControl}
              style={{ width: '90%' }}
              onChange={(e) => {
                setFormData({ ...formData, HE_EDUPOSITION: e.target.value });
              }}
              value={formData.HE_EDUPOSITION}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px', marginTop: '5px' }}>
            <CustomUnicefTextField
              id="filled-basic"
              label={<Grid row container><Typography color="#482642">Remark</Typography></Grid>}
              variantText="filled"
              className={classes.formControl}
              style={{ width: '90%' }}
              onChange={(e) => {
                setFormData({ ...formData, HE_REMARK: e.target.value });
              }}
              value={formData.HE_REMARK}
            />
          </Grid>
        </Grid>
      </Paper>

      <Grid container alignItems="center" justifyContent="center" style={{ paddingTop: '25px', background: '#ffffff' }} row>
        <Grid item xs={'auto'} style={{ width: '15%' }}>
          <Button
            variant="contained"
            style={{ background: '#482642', color: '#fff', width: '90%' }}
            onClick={save} >Save</Button>
        </Grid>
        <Grid item xs={'auto'} style={{ width: '15%' }}>
          <Button
            variant="contained"
            style={{ background: '#482642', color: '#fff', width: '90%' }}
            onClick={cancle} >Cancel</Button>
        </Grid>
      </Grid>

    </div>
  );
}

export default HEForm;