import axios from 'axios';
import {api} from '../utils/helper';

//****************** GET Search Ewar******************//
export const editEWARShow = async (a) => {
    try{
    const resrep = await axios.post(
      `${api}/editewarshow`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );
          console.log(`editEWARShow controller res: `, resrep);
          return resrep;
    }catch (error) {
        console.log(`editEWARShow controller error: `, error?.response?.data);
        alert(error?.response?.data?.message);
    }

}

//****************** GET Ewar******************//
export const editEWAR = async (a) => {
  try{
  const resrep = await axios.post(
    `${api}/editewar`, a,
    {
      headers: {
        authorization: sessionStorage.getItem('token'),
      },
    },
  );
        console.log(`editEWAR controller res: `, resrep);
        return resrep;
  }catch (error) {
      console.log(`editEWAR controller error: `, error?.response?.data);
      alert(error?.response?.data?.message);
  }

}