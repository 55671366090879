import React, { useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { Card } from "@mui/material";
import { Button, Typography } from "@material-ui/core";
import Modals from "../../components/modal";
import _ from 'lodash';

/////////Form////////////
import EWAREditForm from '../../forms/GerenalService/EWAREditForm'

////////Controls////////
import EWARSearchBar from '../../components/controls/EWARSearchBar'

const EditEWARForm = props => {
    
    ///////Background Data///////////
    const [loading, setLoading] = useState(false);
    const [editPage, setEditPage] = useState(false)

      useEffect(() => {
        const fn = async () => {
          const edit = sessionStorage.getItem('editEWAR')
          setEditPage(edit)
          console.log("EWAREditShow from EWAR edit form", edit)
        }
        fn();
      }, [sessionStorage.getItem('editEWAR')])


    return(
        <div style={{ background: '#fcf0f2' }}>
      <Modals open={loading} />
      <Card style={{ background: '#fcf0f2' }}>
        {(editPage === "false") ?
          <><Typography variant="h5" align="center" style={{ color: '#53344d', background: '#fcf0f2', fontWeight: 'bold', padding: '1%' }}>
            EWAR Information</Typography>
            <EWARSearchBar/></>

          : <EWAREditForm editPage={editPage}></EWAREditForm>}


      </Card >

    </div >
    )
}

export default EditEWARForm;