import axios from 'axios';
import {api} from '../utils/helper';

//****************** GET All IMAM Patients by Org ******************//
export const editImamShow = async () => {
    try{
      const a = { orgID: sessionStorage.getItem('org'), 
      sn: sessionStorage.getItem('serviceName'),
      projID:sessionStorage.getItem('project')}
    const resrep = await axios.post(
      `${api}/editimamshow`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );
          console.log(`editImamShow controller res: `, resrep);
          return resrep;
    }catch (error) {
        console.log(`editImamShow controller error: `, error?.response?.data);
        alert(error?.response?.data?.message);
    }

}

//****************** GET Search IMAM Patient ******************//
export const getImamPatient = async () => {
  try{
    const a = { ID: sessionStorage.getItem('searchPatientBtn'),sn: sessionStorage.getItem('serviceName'), orgID: sessionStorage.getItem('org')}
  const resrep = await axios.post(
    `${api}/getimampatient`, a,
    {
      headers: {
        authorization: sessionStorage.getItem('token'),
      },
    },
  );
        console.log(`getImamPatient controller res: `, resrep);
        return resrep;
  }catch (error) {
      console.log(`getImamPatient controller error: `, error?.response?.data);
      alert(error?.response?.data?.message);
  }

}

//****************** GET Search IMAM Patient ID,Date******************//
export const getImamPatientIDDate = async (a) => {
  try{
    //const a = { ID: sessionStorage.getItem('searchPatientBtn'),sn: sessionStorage.getItem('serviceName'), orgID: sessionStorage.getItem('org')}
  const resrep = await axios.post(
    `${api}/getimampatientiddate`, a,
    {
      headers: {
        authorization: sessionStorage.getItem('token'),
      },
    },
  );
        console.log(`getImamPatientIDDate controller res: `, resrep);
        return resrep;
  }catch (error) {
      console.log(`getImamPatientIDDate controller error: `, error?.response?.data);
      alert(error?.response?.data?.message);
  }

}

//****************** GET Search IMAM Patient Date******************//
export const getImamPatientDate = async (a) => {
  try{
    //const a = { ID: sessionStorage.getItem('searchPatientBtn'),sn: sessionStorage.getItem('serviceName'), orgID: sessionStorage.getItem('org')}
  const resrep = await axios.post(
    `${api}/getimampatientdate`, a,
    {
      headers: {
        authorization: sessionStorage.getItem('token'),
      },
    },
  );
        console.log(`getImamPatientDate controller res: `, resrep);
        return resrep;
  }catch (error) {
      console.log(`getImamPatientDate controller error: `, error?.response?.data);
      alert(error?.response?.data?.message);
  }

}