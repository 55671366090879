import React, { useState, useEffect } from "react";
import "./SearchBar.css";
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Grid, List, ListItem, Typography } from "@material-ui/core";
import CustomEWARTable from './CustomEWARTable'
import CustomUnicefTextField from './CustomUnicefTextField'
import CustomEWARDeleteDialog from '../controls/CustomEWARDeleteDialog';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import * as edit from "../../modals/service_editshow"
import Modals from "../modal";

///////////////API/////////////////////
import { editEWARShow } from '../../modals/editewarshow'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));

function EWARSearchBar() {

    const classes = useStyles();

    const [month, setMonth] = useState('999')
    const [year, setYear] = useState('999')
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);

    const search = async () => {
        const a = { orgID: sessionStorage.getItem('org'), yearData: year, monthData: month }
        setLoading(true)
        let res = await editEWARShow(a)
        if (res) {
            //console.log('Data => ', res.data.data.editEWARShow)
            setTableData(res.data.data.editEWARShow)
        }
        setLoading(false)
    }

    const [dialogOpen, setDialogOpen] = useState(false)

    const history = useHistory();

    const editButtonHandle = (event) => {
        console.log(event.currentTarget.value)
        history.push('entrypage')
        sessionStorage.setItem('month', month)
        sessionStorage.setItem('year', year)
        sessionStorage.setItem('editEWAR', true)
    }

    const deleteButtonHandle = (event) => {
        console.log(event.currentTarget.value)
        //sessionStorage.setItem('deleteANCPatient', "true")
        sessionStorage.setItem('month', month)
        sessionStorage.setItem('year', year)
        setDialogOpen(true)
    }

    //Dialog
    const setDialogOpenControl = () => {
        setDialogOpen(true)
    }
    const setDialogCloseControl = async () => {
        setDialogOpen(false)
    }

    useEffect(() => {
        const fn = async () => {
          search()
        }
        fn();
      }, [sessionStorage.getItem('deleteEWAR')])

    return (

        <div>
            <Modals open={loading} />
            {dialogOpen && <CustomEWARDeleteDialog onClose={setDialogCloseControl} open={setDialogOpenControl} />}
            <Grid row container justifyContent={'space-between'} >
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '39%' }}>
                <CustomUnicefTextField
                        label={<Grid row container><Typography color="#482642">Year</Typography>
                            <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
                        select
                        value={year}
                        onChange={(e) => { setYear(e.target.value) }}
                        variantText="filled"
                        style={{ width: '100%', marginBottom: '10px', marginRight: '10px' }}
                        InputLabelProps={{
                            style: { color: '#482642' },
                            shrink: true
                        }}
                        SelectProps={{
                            native: true
                        }}>
                        <option value={999}>-</option>
                        <option value={new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</option>
                        <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                        <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                        <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>
                        <option value={new Date().getFullYear() + 2}>{new Date().getFullYear() + 2}</option>
                    </CustomUnicefTextField>
                    <CustomUnicefTextField
                        label={<Grid row container><Typography color="#482642">Month</Typography>
                            <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
                        select
                        value={month}
                        onChange={(e) => { setMonth(e.target.value) }}
                        variantText="filled"
                        style={{ width: '140%', marginBottom: '10px', marginRight: '10px' }}
                        InputLabelProps={{
                            style: { color: '#482642' },
                            shrink: true
                        }}
                        SelectProps={{
                            native: true
                        }}>
                        <option value={999}>-</option>
                        <option value={1}>January</option>
                        <option value={2}>February</option>
                        <option value={3}>March</option>
                        <option value={4}>April</option>
                        <option value={5}>May</option>
                        <option value={6}>June</option>
                        <option value={7}>July</option>
                        <option value={8}>August</option>
                        <option value={9}>September</option>
                        <option value={10}>October</option>
                        <option value={11}>November</option>
                        <option value={12}>December</option>
                    </CustomUnicefTextField>
                    
                    <Button
                        variant="contained"
                        className={classes.button}
                        disabled={month === '999' || year === '999'}
                        style={{ width: '80%', marginBottom: '20px', marginTop: '10px', color: (month === '999' || year === '999') ? "#a49c9e" : "#53344d", backgroundColor: (month === '999' || year === '999') ? '#DDD3D5' : 'white' }}
                        startIcon={<SearchIcon />}
                        onClick={search}>
                        Search
                    </Button>
                </div>

                <Grid item >
                    <Button
                        variant="contained"
                        disabled={!tableData.length}
                        style={{ backgroundColor: (!tableData.length) ? '#DDD3D5' : '#482642', color: (!tableData.length) ? '#a49c9e' : 'white' }}
                        className={classes.button}
                        startIcon={<EditIcon />}
                        onClick={editButtonHandle}>
                        Edit
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={!tableData.length}
                        className={classes.button}
                        startIcon={<DeleteIcon />}
                        onClick={deleteButtonHandle}>
                        Delete
                    </Button>
                </Grid>
            </Grid>

            <CustomEWARTable searchData={tableData} />
        </div>
    );
}

export default EWARSearchBar;