import axios from 'axios';
import { api } from '../utils/helper';

//****************** GET village test ******************//
export const villageTest = async (a) => {
  
    try {
  
      const resrep = await axios.post(
        `${api}/villagetest`, a,
        {
          headers: {
            authorization: sessionStorage.getItem('token'),
          },
        },
      );
  
      console.log(`villageTest controller res: `, resrep);
      return resrep;
    } catch (error) {
      console.log(`villageTest controller error: `, error?.response?.data);
      alert(error?.response?.data?.message);
    }
  
  }