import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";

import { Typography } from '@material-ui/core';

import CustomHEDeleteDialog from '../controls/CustomHEDeleteDialog';


const useStyles = makeStyles({
    container: {
        maxHeight: '400px',
        width: '98%',
        borderRadius: '5px',
        position: 'revert',
        background: '#fcf0f2',
        marginLeft: '1%',
        marginRight: '1%'
    },
});

const ageUnder18 = 'AGE <=18'
const ageBetween1924 = 'AGE 19-24'
const ageAbove25 = 'AGE >=18'



export default function CustomGMPatientTable(props) {

    const classes = useStyles();
    const { tableID, searchData, tdata } = props
    const [dialogOpen, setDialogOpen] = useState(false)

    const history = useHistory();

    const editButtonHandle = (event) => {
        console.log(event.currentTarget.value)
        history.push('entrypage')
        sessionStorage.setItem('editHE', true)
        sessionStorage.setItem('heorg', sessionStorage.getItem('org'))
        sessionStorage.setItem('editServicePatientID', event.currentTarget.value)

    }

    const deleteButtonHandle = (event) => {
        console.log(event.currentTarget.value)
        sessionStorage.setItem('deleteHE', "true")
        sessionStorage.setItem('heorg', sessionStorage.getItem('org'))
        sessionStorage.setItem('deleteServicePatientID', event.currentTarget.value)
        setDialogOpen(true)
    }

    //Dialog
    const setDialogOpenControl = () => {
        setDialogOpen(true)
    }
    const setDialogCloseControl = async () => {

        setDialogOpen(false)

    }

    return (
        <>
            {dialogOpen && <CustomHEDeleteDialog onClose={setDialogCloseControl} open={setDialogOpenControl} />}
            <TableContainer
                className={classes.container}>
                <Table
                    id={tableID}
                    aria-label="spanning table"
                    size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold', width: '8%' }} rowSpan={2}>Date</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }} rowSpan={2}>Organization</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }} rowSpan={2}>State/Region</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }} rowSpan={2}>Township</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }} rowSpan={2}> Village </TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }} rowSpan={2}>HE Topic</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }} rowSpan={2}>Health Educator Name</TableCell>
                            {/* <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }} colSpan={2}>{ageUnder18}</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }} colSpan={2}>{ageBetween1924}</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }} colSpan={2}>{ageAbove25}</TableCell> */}
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }} rowSpan={2}>Edit</TableCell>
                            {/* <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }} rowSpan={2}>Delete</TableCell> */}
                        </TableRow>
                        {/* <TableRow>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }}>Male</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }}>Female</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }}>Male</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }}>Female</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }}>Male</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }}>Female</TableCell>
                        </TableRow> */}
                    </TableHead>
                    <TableBody >
                        {searchData.length ?
                            searchData.map((row) => (
                                <TableRow>
                                    <TableCell align="center" >{moment(row.PROVIDEDDATE).format('DD-MM-YYYY')}</TableCell>
                                    <TableCell align="center" >{row.ORGNAME}</TableCell>
                                    <TableCell align="center" >{row.STATEREGION}</TableCell>
                                    <TableCell align="center" >{row.TSPNAME}</TableCell>
                                    <TableCell align="center" >{row.VILLAGENAME}</TableCell>
                                    <TableCell align="center" >{row.HETOPICNAME}</TableCell>
                                    <TableCell align="center" >{row.HE_EDUNAME}</TableCell>
                                    {/* <TableCell align="center" >{row['<=18M']}</TableCell>
                                    <TableCell align="center" >{row['<=18F']}</TableCell>
                                    <TableCell align="center" >{row['>19<24M']}</TableCell>
                                    <TableCell align="center" >{row['>19<24F']}</TableCell>
                                    <TableCell align="center" >{row['>=25M']}</TableCell>
                                    <TableCell align="center" >{row['>=25F']}</TableCell> */}
                                    <TableCell align="center">
                                        {(row.HE_ORG === sessionStorage.getItem('org')) ?
                                            <IconButton value={row.HE_HDID} onClick={editButtonHandle}>
                                                <EditIcon style={{ color: "#d91d4c" }} />
                                            </IconButton> :
                                            <IconButton value={row.HE_HDID} disabled onClick={editButtonHandle}>
                                                <EditIcon style={{ color: "#b9b9b9" }} />
                                            </IconButton>}
                                    </TableCell>
                                    {/* <TableCell align="center">
                                        {(row.HE_ORG === sessionStorage.getItem('org')) ?
                                            <IconButton value={row.HE_HDID} onClick={deleteButtonHandle}>
                                                <DeleteIcon style={{ color: "#d91d4c" }} />
                                            </IconButton> :
                                            <IconButton value={row.HE_HDID} disabled onClick={deleteButtonHandle}>
                                                <DeleteIcon style={{ color: "#b9b9b9" }} />
                                            </IconButton>}
                                    </TableCell> */}

                                </TableRow>
                            ))
                            : null}
                    </TableBody>
                </Table>
            </TableContainer>

            <div style={{ margin: 10 }}>
                {!searchData.length && <Typography variant="body" align="center" style={{ color: '#53344d' }}>
                    No Data</Typography>}
            </div>

        </>
    );
}

/* tdata.map((row) => (
    <TableRow>
        <TableCell align="center" >{row.REGID}</TableCell>
        <TableCell align="center" >{moment(row.PROVIDEDDATE).format('DD-MM-YYYY')}</TableCell>
        <TableCell align="center" >{row.REGNAME}</TableCell>
        <TableCell align="center" >{row.AGE + row.AGEUNIT}</TableCell>
        <TableCell align="center" >{row.TSP_NAME}</TableCell>
        <TableCell align="center" >{row.CLN_NAME}</TableCell>
        <TableCell align="center" >{sessionStorage.getItem('orgName')}</TableCell>
        <TableCell align="center">
            <IconButton value={row.REGID + ',' + row.ID} onClick={editButtonHandle}>
                <EditIcon style={{ color: "#d91d4c" }} />
            </IconButton>

        </TableCell>
        <TableCell align="center">
            <IconButton value={row.REGID + ',' + row.ID} onClick={deleteButtonHandle}>
                <DeleteIcon style={{ color: "#d91d4c" }} />
            </IconButton>

        </TableCell>
    </TableRow> */
