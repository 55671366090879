import axios from 'axios';
import { api } from '../utils/helper';


//****************** Patient ID Check ******************//
export const patientIDCheck = async (a) => {
  
    console.log("ID from database => ", a.regID)
  
    try {
  
      const resrep = await axios.post(
        `${api}/patientidcheck`, a,
        {
          headers: {
            authorization: sessionStorage.getItem('token'),
          },
        },
      );
  
      console.log(`patientIDCheck controller res: `, resrep);
      return resrep;
    } catch (error) {
      console.log(`patientIDCheck controller error: `, error?.response?.data);
      alert(error?.response?.data?.message);
    }
  
  }